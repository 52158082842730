// src/components/Header.js
import React from "react";
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-main">
        <div className="header-container container">
            <div className="header-wrap">
                <div className="header-logo logo animated fadeInUp">
                    <a href="#" className="logo-link">
                        <img className="logo-dark" src={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} srcSet={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} alt="logo" />
                        <img className="logo-light" src={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} srcSet={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} alt="logo" />
                    </a>
                </div>
                <div className="header-nav-toggle">
                    <a href="#" className="navbar-toggle" data-menu-toggle="example-menu-04">
                        <div className="toggle-line"><span></span></div>
                    </a>
                </div>
                <div className="header-navbar header-navbar-s1">
                    <nav className="header-menu" id="example-menu-04">
                        <ul className="menu menu-s2 animated fadeInDown">
                            <li className="menu-item">
                                {/* <a className="menu-link nav-link fw-6" href="#">Home</a> */}
                                <Link className="menu-link nav-link fw-6" to="/">Home</Link>
                            </li>
                            <li className="menu-item has-sub">
                                <a className="menu-link nav-link fw-6 menu-toggle" href="#">Company</a>
                                <div className="menu-sub menu-drop menu-mega menu-mega-2clmn bg-white">
                                    <div className="menu-mega-innr">
                                        <div className="menu-mega-list d-flex align-items-center">
                                            <h3 className="title title-md pd-s ">About Us</h3>
                                        </div>
                                        <ul className="menu-mega-list pdb-xs">
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">About</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/about">About</Link>
                                                <p className="tc-default fz-8 pdx-s">Discover our Vision, Mission and the Team</p>
                                            </li>
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">Support</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/support">Support</Link>
                                                <p className="tc-default fz-8 pdx-s">Get Help 24/7</p>
                                            </li>
                                            <li className="menu-item mg-s">
                                                <a className="fz-5 fw-5 pdx-s" href="#">Security</a>
                                                <p className="tc-default fz-8 pdx-s">Learn About Our Leading Protection</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item has-sub">
                                <a className="menu-link nav-link fw-6 menu-toggle" href="#" onClick={e => e.preventDefault()}>Products</a>
                                <div className="menu-sub menu-drop menu-mega menu-mega-3clmn bg-white">
                                    <div className="menu-mega-innr">
                                        <div className="menu-mega-list d-flex align-items-center">
                                            <h3 className="title title-md pd-s ">Get <br/> Started</h3>
                                        </div>
                                        <ul className="menu-mega-list pdb-xs">
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">Exchange</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/exchange">Exchange</Link>
                                                <p className="tc-default fz-8 pdx-s"></p>
                                            </li>
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">DEFI</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/wallet">DEFI</Link>
                                                <p className="tc-default fz-8 pdx-s">DEFI wallet</p>
                                            </li>
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">Secure Chat</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/chat">SecureChat</Link>
                                                <p className="tc-default fz-8 pdx-s"></p>
                                            </li>
                                        </ul>
                                        <ul className="menu-mega-list pdb-xs">
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">Kyberultra</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/kyberultra">Kyberultra</Link>
                                            </li>
                                            <li className="menu-item mg-s">
                                                {/* <a className="fz-5 fw-5 pdx-s" href="#">Spend</a> */}
                                                <Link className="fz-5 fw-5 pdx-s" to="/spend">Spend</Link>
                                                <p className="tc-default fz-8 pdx-s">Crypto Debit Card</p>
                                            </li>
                                            <li className="menu-item mg-s">
                                                <a className="fz-5 fw-5 pdx-s" href="#">Grow & Stake</a>
                                                <p className="tc-default fz-8 pdx-s">Crypto Earn</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item">
                                {/* <a className="menu-link nav-link fw-6" href="#">Services</a> */}
                                <Link className="menu-link nav-link fw-6" to="/services">Services</Link> 
                            </li>
                        </ul>
                        <ul className="menu-btns animated fadeInDown">
                            <li><a href="#" className="btn btn-rg btn-auto btn-outline btn-info on-bg-theme btn-round"><span>Whitepaper</span></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Header;
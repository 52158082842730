// src/components/PreSale.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const PreSale = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            window.ShieldApp.countdown();
        }
    }, []);

  return (
    <div className="header-banner bg-theme">
        <div className="nk-banner overlay-x">
            <div className="banner banner-single">
                <div className="banner-wrap">
                    <div className="container container-z">
                        <div className="row align-items-center justify-content-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <p className="text-center text-white pdt-l">Our Built-In Token</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h2 className="title title-md text-center text-white">KYBERULTRA</h2>
                            </ScrollAnimation>
                            <div className="col-lg-6 col-mb-10 pdt-r pdr-r">
                                <ScrollAnimation className="nk-block-text text-justify" animateIn="fadeInUp">
                                    <p className="text-white">KYB is the native and proprietary token of <b>ShieldTech</b>, which was launched in 2023 as a profit-sharing token. KYB operates as an ERC-20 token running on our own EVM network and supported by majority of Ethereum wallets. The toal supply of KYB was set at 400 million units.</p>

                                    <p className="text-white">In the forthcoming phase of <b>ShieldTech</b> journey, as its decentralized trading and financial solutions goes live and will be available for use, KYB is slated to become not only the intrinsic asset for <b>ShieldTech’s</b> decentralized financial sevices but also the governance token of <b>ShieldTech</b> community in the future.</p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-lg-6 col-mb-10 pdt-s pdl-r">
                                <ScrollAnimation className="nk-block-text text-justify" animateIn="fadeInUp">
                                    <p className="text-white">Emphasizing its commitment, <b>ShieldTech</b> has set forth the focus on “Empowering KYB” will be the key priority of <b>ShieldTech</b> and will build KYB as a highly exceptional product rather than a simple token. Which is bound to further diversify the benefits that KYB holders can access.</p>
                                    <p className="text-white">In the long run, KYB will assume to perform as the essential key to the entire ShieldTech ecosystem. As <b>ShieldTech</b> continues to develop its decentralized exchange (DEX) and broaden its scope, KYB is poised to function as the underlying fuel and universal token for upcoming <b>ShieldTech</b> decentralized offerings.</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 text-center">
                                <ScrollAnimation className="banner-caption wide-auto-xl pdb-s pdt-s tc-white" animateIn="fadeInUp">
                                    <div className="cpn-head">
                                        <h1 className="title title-md tc-white fz-3">Countdown to KyberUltra Coin Sale</h1>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-xl-8 col-lg-10">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="tc-light text-center pdb-r">
                                    </div>
                                    <div className="token-status bg-white tc-dark token-status-s6 shadow-dark round mgb-x pdb-r">
                                        <div className="countdown-s3 countdown-s5 countdown-large countdown-thin countdown" data-date="2023/12/30"></div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default PreSale;

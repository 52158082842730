// src/components/Welcome.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

function Welcome() {
    
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            window.ShieldApp.particles();
        }
    }, []);

  return (
    <div className="header-banner bg-theme-dark">
        <div className="nk-banner">
            <div className="banner banner-mask-fix banner-fs banner-single tc-light">
                <div className="banner-wrap">
                    <div className="container">
                        <div className="row align-items-center justify-content-center gutter-vr-30px">
                            <div className="col-lg-6 order-lg-last">
                                <ScrollAnimation className="banner-gfx banner-gfx-re-s3 text-center" animateIn='fadeInUp' initiallyVisible={true}>
                                    <img className="w-75" src={process.env.PUBLIC_URL + '/assets/images/header/gfx-e.png'} alt="header" />
                                </ScrollAnimation>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-caption wide-auto text-center text-lg-start">
                                    <div className="cpn-head mt-0">
                                        <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
                                            <h5>Welcome to</h5>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
                                            <h1 className="title title-xl-2 title-semibold">Shield Tech</h1>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="cpn-text cpn-text-s1">
                                        <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
                                            <p className="lead text-justify">
                                                We are a Cryptocurrency Platform that specialized in developing 
                                                <b> Secure</b>,
                                                <b> Decentralized</b> and <b>Scalable </b>
                                                Applications.
                                            </p>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
                                            <ul className="list list-check">
                                                <li>Communication</li>
                                                <li>Secure Exchanges</li>
                                                <li>Financial Services</li>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>
                                    <div className="cpn-btns">
                                        <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
                                            <ul className="btn-grp">
                                                <li><a href="#" className="btn btn-md btn-info btn-round">Whitepaper</a></li>
                                                <li><a href="#token" className="btn btn-md btn-info btn-info-alternet btn-round">Token Distribution</a></li>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="nk-ovm mask-c-dark shape-v mask-contain-bottom"></div>
        <div id="particles-bg" className="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div> 
    </div>
  );
}

export default Welcome;
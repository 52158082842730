// src/components/AboutUs.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Vission from "../components/Vission";
import Team from "../components/Team";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import ScrollAnimation from 'react-animate-on-scroll';

const AboutUs = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            
            window.ShieldApp.preLoader();
            window.ShieldApp.headerSticky();
            window.ShieldApp.MainMenu();
            window.ShieldApp.particles();
        }
    }, []);
    
  return (
    <div className="AboutUs-page nk-wrap">

        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <Header />

            <div className="header-banner bg-theme-grad">
                <div className="nk-banner">
                    <div className="banner banner-page">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <ScrollAnimation className="cpn-head" animateIn="fadeInUp" initiallyVisible={true}>
                                                <h2 className="title ttu">About Shieldtech</h2>
                                                <p>We are a group of business innovators, enriched by extensive experience in both financial and cryptographic security domains. Together with our proficient team. Let's jointly face the future with unwavering confidence and determination.</p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>
                <div id="particles-bg" className="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div>
            </div>
        </header>

        <main className="nk-pages">

            <section className="section bg-dark section-about">
                <div className="container overflow-hidden">
                    <div className="nk-block">
                        <div className="row gutter-vr-30px align-items-center">
                            <div className="col-lg-6">
                                <ScrollAnimation className="nk-block-text text-center" animateIn="fadeInUp">
                                    <p className="text-justify text-white">Our joint idea that binds us together: Foster the widespread embrace of a decentralized economy by providing services that are both secure and dependable.</p>
                                    <p className="text-justify text-white">We provide established and genuinely secure user experiences for storing and overseeing digital assets, crafting robust hardware and software solutions.</p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-lg-6">
                                <ScrollAnimation className="gfx py-4" animateIn="fadeInUp">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/azalea/gfx-e.png'} alt="gfx" />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Vission />

            <section className="section section-team bg-theme">
                <div className="container">
                    <div className="nk-block nk-block-team-featured team-featured">
                        <div className="row align-items-center">
                            <ScrollAnimation className="col-lg-5 mb-4 mb-lg-0" animateIn="fadeInUp">
                                <h2 className="title title-thin text-white">BEHIND THE IDEA</h2>
                            </ScrollAnimation>
                            <div className="col-lg-7">
                                <div className="team-featured-cont">
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="text-center">
                                            Shield Tech Group, a pioneering firm dedicated to developing secure and decentralized applications that
                                            empower individuals and organizations alike. We vision to redefine the future of applications, placing
                                            security, privacy, and decentralization at the heart of every innovation.
                                        </p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="text-center">
                                            Proudly having a dynamic and accomplished Board of Directors, Founders and dedicated Team, driving
                                            our commitment to revolutionize the landscape of digital finance. Our distinguished leaders bring a
                                            wealth of experience, innovation, and strategic insight to guide our company into the future of blockchain
                                            technology and cryptocurrency. 
                                        </p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="text-center">
                                            By staying ahead of industry trends, we ensure that ShieldTechGroup remains a user-friendly and
                                            innovative platform for all.
                                        </p>
                                    </ScrollAnimation>
                                    
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="text-center">
                                            Together, our team is united by a shared vision of transforming the way the world engages with digital
                                            finance. Through collaboration, innovation, and a relentless pursuit of excellence, we are poised to lead
                                            ShieldTechGroup into a future where decentralized finance is accessible, secure, and empowering for
                                            all.
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Team />

        </main>

        <div className="nk-ovm nk-ovm-fixed shape-z5">
            <div className="ovm-line"></div>
        </div>

        <footer className="nk-footer bg-theme-alt section-connect">
            <Subscibe />
            <Footer />
        </footer>
        
        <div className="preloader"><span className="spinner spinner-round"></span></div>
    </div>
  );
};

export default AboutUs;

// src/components/Security.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Security = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section bg-dark-alt tc-light">
        <div className="container overflow-hidden">
            <ScrollAnimation className="section-head text-center wide-auto-md wide-auto" animateIn="fadeInUp" initiallyVisible={true}>
                <h2 className="title">WITH SHIELDCHAT</h2>
                <p>Improve the security of sensitive information when shared and when stored in ShieldChat.</p>
                <h6>SECURITY TAKES A QUANTUM LEAP</h6>
                <p>PRO and LITE version.</p>
            </ScrollAnimation>
            <div className="nk-block nk-block-features-s3">
                <div className="row justify-content-center gutter-vr-30px">
                    <div className="col-lg-6 col-md-6">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt animated" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-shiled"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">END-TO-END ENCRYPTION</h4>
                                <p>Keep your chats, remote meetings, and files secure with end-to-end encryption.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-safety"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">POST-QUANTUM SECURITY</h4>
                                <p>Protect your data from future access enabled by quantum computers.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-data-server"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">SECURED DATA STORAGE</h4>
                                <p>Safeguard your information stored in ShieldChat with a local encrypted container.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-user-chat"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">COMMUNICATION COMPLIANCE</h4>
                                <p>Keep your team communication compliant to organizational data policies.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-user-setting"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">MANAGE USER LICENSES AND ROLES</h4>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-bulb-2"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">CONFIGURE GLOBAL WORKSPACE SETTINGS</h4>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation className="feature feature-s3 feature-center card bg-theme-alt" animateIn="fadeInUp">
                            <div className="feature-icon w-100"><em className="icon icon-lg ikon ikon-clip-board"></em></div>
                            <div className="feature-text">
                                <h4 className="title title-md title-dark">SET DATA RETENTION POLICIES</h4>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Security;

// src/components/Steps.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Steps = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section bg-theme-dark-alt tc-light">
        <div className="container overflow-hidden">
            <ScrollAnimation className="section-head text-center wide-auto-sm tc-light" animateIn="fadeInUp">
                <div className="section-head-line">
                    <span className="line-1"></span>
                    <span className="line-2"></span>
                    <span className="line-3"></span>
                    <span className="line-4"></span>
                    <span className="line-5"></span>
                    <span className="line-6"></span>
                    <span className="line-7"></span>
                    <span className="line-8"></span>
                </div>
                <h2 className="title title-s4" title="HOW">How Does It Works?</h2>
                <p className="" animateIn="fadeInUp"> A stake involves holding a specific cryptocoin in a wallet for a particular period of time. A staking requirement is the minimum amount of said cryptocoin that is required to stake.</p>
            </ScrollAnimation>
            <div className="nk-block nk-block-text-wrap">
                <ScrollAnimation className="section-head text-center pb-2" animateIn="fadeInUp" >
                    <h2 className="title title-regular">Stake your Crypto in 3 easy steps:</h2>
                    <p>It's easy as a breeze to stake your crypto with SHIELDMANAGER. The whole process is completely cold and easy to follow.</p>
                </ScrollAnimation>

                <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
                    <div className="col-md-4">
                        <ScrollAnimation className="feature feature-center" animateIn="fadeInUp" >
                            <div className="feature-serial text-light"><span>1</span></div>
                            <div className="feature-text feature-text-wd">
                                <h5 className="title title-md">Get your wallet ready</h5>
                                <p className="mx-auto">Install the SHIELDMANAGER and hold your coins there</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-4">
                        <ScrollAnimation className="feature feature-center" animateIn="fadeInUp" >
                            <div className="feature-serial text-light"><span>2</span></div>
                            <div className="feature-text feature-text-wd">
                                <h5 className="title title-md">Stake your coins</h5>
                                <p className="mx-auto">To stafe coins like Ethereum, BTC, Kyberultra etc</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-4">
                        <ScrollAnimation className="feature feature-center" animateIn="fadeInUp" >
                            <div className="feature-serial text-light"><span>3</span></div>
                            <div className="feature-text feature-text-wd">
                                <h5 className="title title-md">and you're done!</h5>
                                <p className="mx-auto">Get passive income on your crypto assets</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Steps;

// src/pages/Home.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Welcome from "../components/Welcome";
import PreSale from "../components/PreSale";
import About from "../components/About";
import Swap from "../components/Swap";
import Token from "../components/Token";
import Roadmap from "../components/Roadmap";
import Partners from "../components/Partners";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import $ from 'jquery';

function Home() {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            // window.ShieldApp.countdown();
            window.ShieldApp.preLoader();
            window.ShieldApp.headerSticky();
            window.ShieldApp.MainMenu();
            // window.ShieldApp.Chart.ChartJs();
            
        }
    }, []);

  return (
    <div className="Home-page nk-wrap">

        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <Header />
            <Welcome />
        </header>  

        <main className="nk-pages">
            <PreSale />
            <About />
            <Swap />
            <Token />
            <Roadmap />
            <Partners />
        </main>

        <div className="nk-ovm nk-ovm-fixed shape-z5">
            <div className="ovm-line"></div>
        </div> 

        <footer className="nk-footer bg-theme-alt section-connect">
            <Subscibe />
            <Footer />
        </footer>
        
        <div className="preloader"><span className="spinner spinner-round"></span></div>
    </div>
  );
}

export default Home;
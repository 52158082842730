// src/components/Token.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Token = () => {
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            window.ShieldApp.Chart.ChartJs();
        }
    }, []);
  return (
    <section className="section section-tokensale tc-light bg-theme-dark-alt" id="token">
        <div className="container overflow-hidden">
            <div className="section-head text-center wide-auto-sm">
                <ScrollAnimation animateIn="fadeInUp">
                    <h2 className="title title-s4" title="TOKEN">Token Distribution</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <p>Total supply of KyberUltra coin: <b>400.000.000</b></p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <p>KyberUltra Is Backed By The Performance Of The Shield Tech Group.</p>                    
                </ScrollAnimation>
            </div>
            <div className="nk-block nk-block-token mgb-m30">
                <div className="row align-items-center justify-content-between gutter-vr-50px">
                    <div className="col-lg-6">
                        <div className="row gutter-vr-30px">
                            <div className="col-sm-6">
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s1 title-xs-s2">Private</h6>
                                </ScrollAnimation>
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s6 title-xs-s2"></h6>
                                    <p className="text-justify">Private capital ownership gives significant control over shield tech's governance to the board and directors.</p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-sm-6">
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s1 title-xs-s2">Public</h6>
                                </ScrollAnimation>
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s6 title-xs-s2"></h6>
                                    <p className="text-justify">
                                        Public Investors has
                                        the opportunity to
                                        participate in Shield
                                        Tech’s ownership and
                                        growth.
                                    </p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-sm-6">
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s6 title-xs-s2"></h6>
                                    <p className="text-justify">Private capital comprises 60% of ownership, held by the Board and Directors of Shield Tech Group.</p>
                                </ScrollAnimation>
                            </div>
                            <div className="col-sm-6">
                                <ScrollAnimation className="stage-info" animateIn="fadeInUp">
                                    <h6 className="title title-s6 title-xs-s2"></h6>
                                    <p className="text-justify">
                                        Public Capital is 40%
                                        of the total supply
                                        and open for sale for
                                        Public Investors.
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="row align-items-center gutter-vr-50px">
                            <div className="col-xxl-12 col-lg-12">
                                <ul className="chart-data-s2 row mb-2" data-canvas="token-distribution" data-canvas-type="pie" data-border-color="#0f1932">
                                    <li className="col-sm-6" data-color="#1f2e4d" data-color-hover="#109eff" data-label="Private Capital" data-title="Private Capital" data-subtitle="of the total coins will be retained by Board and Directors holding" data-amount="60"></li>
                                    <li className="col-sm-6" data-color="#1f2e4d" data-color-hover="#109eff" data-label="Public Capital" data-title="Public Capital" data-subtitle="of the total coins will be made available to Public Investors" data-amount="40"></li>
                                </ul>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <canvas className="chart-canvas" id="token-distribution"></canvas>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Token;

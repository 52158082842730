// src/components/Wallet.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import WalletFeatures from "../components/WalletFeatures";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import ScrollAnimation from 'react-animate-on-scroll';

const Wallet = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            
            window.ShieldApp.preLoader();
            window.ShieldApp.headerSticky();
            window.ShieldApp.MainMenu();
            window.ShieldApp.particles();
        }
    }, []);
    
  return (
    <div className="Wallet-page nk-wrap">

        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <Header />

            <div className="header-banner bg-theme-grad">
                <div className="nk-banner">
                    <div className="banner banner-page pb-0">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <ScrollAnimation className="cpn-head" animateIn="fadeInUp" initiallyVisible={true}>
                                                <h2 className="title ttu">DEFI WEB3 WALLET</h2>
                                                <p>
                                                    We are delighted to announce the development of
                                                    ShieldTech Wallet. We are building both software and
                                                    hardware wallets, with the software wallet serving as the
                                                    foundation for the development.
                                                </p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>
                <div id="particles-bg" className="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div>
            </div>
        </header>

        <main className="nk-pages">
            <section className="section bg-dark-alt no-pdy pdy-x">
                <div className="container">
                    <div className="nk-block block-contact">
                        <div className="row justify-content-center g-0">
                            <div className="col-lg-9">
                                <div className="subscribe-wrap bg-theme-alt tc-light round">
                                    <div className="section-head section-head-sm wide-auto-sm">
                                        <ScrollAnimation className="row pdy-l"animateIn="fadeInUp">
                                            <div className="col-sm-6 text-left">
                                                <h2 className="title title-lg tc-white">Your Keys</h2>
                                            </div>
                                            <div className="col-sm-6 text-end">
                                                <h2 className="title title-lg tc-white">Your Crypto</h2>
                                            </div>
                                        </ScrollAnimation>
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <p className="text-white text-center">
                                                A non-custodial wallet providing you an access to a
                                                comprehensive range of DeFi services all in one location.
                                            </p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <WalletFeatures />

            <section className="section bg-dark-alt pdy-x">
                <div className="container tc-light">
                    <ScrollAnimation className="section-head text-center wide-auto" animateIn="fadeInUp">
                        <h2 className="title title-lg tc-white">DeFi Desktop Wallet</h2>
                        <h6 className="title title-xs ttn tc-white">Restore your DeFi Wallet with a 12/24-words recovery phrase.</h6>
                        <h6 className="title title-xs ttn tc-white">Access advanced features, like governance, and more.</h6>
                        <h6 className="title title-xs ttn tc-white">Manage Ledger assets on Desktop easily.</h6>
                    </ScrollAnimation>
                    <div className="nk-block">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="nk-block-text text-center">
                                    <div className="nk-block-video tc-light">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/products/b.jpg'} alt="video" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section ov-h bg-theme">
                <div className="nk-block nk-block-download">
                    <div className="container tc-light">
                        <div className="row align-items-center gutter-vr-30px justify-content-between">
                            <div className="col-md-5 col-mb-8 m-auto order-last order-md-first">
                                <div className="nk-block-img">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-z-e.png'} alt="app" />
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-6">
                                <ScrollAnimation className="nk-block-text pb-md-5 text-center text-md-start" tagName="span" animateIn="fadeInUp">
                                    <h2 className="title title-thin title-dark">SECURITY</h2>
                                    <h6 className="title title-xs tc-white">Security is our top priority.</h6>
                                    <p>Your private keys are encrypted locally on your device with Secure Enclave, protected by Biometric and 2-Factor Authentication.</p>
                                    <h6 className="title title-xs tc-white">Your private keys, now and forever.</h6>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <div className="nk-ovm nk-ovm-fixed shape-z5">
            <div className="ovm-line"></div>
        </div>

        <footer className="nk-footer bg-theme-alt section-connect">
            <Subscibe />
            <Footer />
        </footer>
        
        <div className="preloader"><span className="spinner spinner-round"></span></div>
    </div>
  );
};

export default Wallet;

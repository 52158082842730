// src/components/Why.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Why = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section bg-alternet tc-light">
        <div className="container overflow-hidden">
            <div className="section-head text-center wide-auto-sm wide-auto">
                <h2 className="title">WHY SHIELDCHAT</h2>
            </div>
            <div className="row align-items-center justify-content-center gutter-100px">
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-lock tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">BETTER SECURITY</h5>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-shield-alt tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">SECURE COMMUNICATION CHANNEL</h5>
                            <p>Keep teams connected wherever they work with the confidence of secure and private communication.</p>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-share tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">MESSAGING & FILE SHARING</h5>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="row align-items-center justify-content-center gutter-100px mgt-r">
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-video tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">VOICE & VIDEO CALLING</h5>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-tv tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">SCREEN SHARING</h5>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
                    <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
                        <div className="feature-img-xl mb-4"><em className="icon icon-lg fa fa-microphone tc-info"></em></div>
                        <div className="feature-text">
                            <h5 className="title title-sm">VOICE NOTES</h5>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </section>
  );
};

export default Why;
